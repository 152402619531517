/* Container for the start screen */
.start-screen-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height to center the content vertically */
    padding: 0 20px; /* Side padding for spacing */
    position: relative; /* Enables absolute positioning for children within this container */
}
  
/* Map container
.map-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 60vh;
    opacity: 0.6; /* Adjust opacity to make the map more transparent 
    z-index: 1; /* Ensure the map is below the button overlay 
}  */

/* Full-screen map background */
.full-screen-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6; /* Adjust opacity for better contrast with overlay */
    z-index: 1;
    /* transform: translateY(-10%); Moves the map 10% higher */

}


@media (min-width: 768px) {
    .map-container {
        height: 500px;
    }
}
  
/* Overlay for button and goal text */
#mapandbutton{
    position: relative;
}
.button-overlay {
    position: absolute;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2; /* Higher z-index to appear above the map */
    width: 100%; /* CHANGED: Full width to span the screen */
    /* height: 75%; Cover three-quarters of the map */
    justify-content: center; /* Center the content within the overlay */
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

/* Start button styling */
.start-button {
    background-color: #FF5722;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 30px 30px;
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 5px;
    width: 100px;
    height: 100px;
    display: flex; /* Added for centering */
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
}

@media (min-width: 768px) {
    .start-button {
        font-size: 1.8rem;
        width: 100px;
        height: 100px;
    }
}
/* Goal and Voice styling */
.goal, .voice {
    background-color: #b0b0b0; /* Darker grey background */
    color: #FF5722; /* Orange font color */
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none; /* No underline */
    margin-top: 8px;
    padding: 8px 16px;
    border-radius: 10px;
    text-align: center;
    width: 80%; /* Full width across screen */
    box-sizing: border-box; /* Ensures padding does not affect total width */
}

@media (min-width: 768px) {
    .goal, .voice {
        font-size: 1.5rem;
    }
}

/* Onboarding overlay */
.onboarding-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Darker semi-transparent overlay */
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Added padding for small screens */
}

/* Onboarding card */
.onboarding-card {
    background-color: #fff;
    border-radius: 16px; /* Slightly more rounded corners for a modern feel */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Softer, larger shadow */
    padding: 30px 40px;
    text-align: center;
    width: 100%;
    max-width: 450px;
}

/* Onboarding SVG image */
.onboarding-image {
    width: 100%; /* Fully responsive */
    max-width: 500px; /* Constrain size */
    margin-bottom: 20px; /* Space between image and title */
}

/* Onboarding heading */
.onboarding-card h2 {
    font-size: 26px;
    margin-bottom: 15px;
    color: #333;
    font-weight: bold;
}

/* Onboarding list */
.onboarding-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
    margin: 0 0 20px 0; /* Add spacing below the list */
}

.onboarding-list li {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px; /* Space for custom bullet */
}

/* Custom bullet for list items
.onboarding-list li::before {
    content: '•'; Use a dot or custom character
    position: absolute;
    left: 0;
    color: #FF5722; Orange to match theme
    font-size: 18px;
    line-height: 1;
} */

/* Onboarding button */
.onboarding-button {
    background-color: #FF5722;
    color: white;
    border: none;
    border-radius: 24px; /* Rounded button */
    padding: 12px 30px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(255, 87, 34, 0.4); /* Subtle button shadow */
}

.onboarding-button:hover {
    background-color: #e64a19;
    transform: scale(1.05); /* Slight hover animation */
    box-shadow: 0 6px 12px rgba(255, 87, 34, 0.5);
}
