[data-component='App'] {
  height: 100%;
  width: 100%;
  position: relative;
}

/* App.scss */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row; /* On larger screens, make it horizontal */
  }
}

/* Responsive font sizes for time and distance displays */
.time-display, .distance-display {
  font-size: 6vw; /* Larger font size for smaller screens */
}

@media (min-width: 768px) {
  .time-display, .distance-display {
    font-size: 2rem; /* Fixed size for larger screens */
  }
}

/* Example layout adjustment for stats */
.stats-container {
  display: flex;
  flex-direction: row; /* Horizontal on large screens */
  justify-content: space-around;
  width: 100%;
  max-width: 800px; /* Limit width for large screens */
}

@media (max-width: 768px) {
  .stats-container {
    flex-direction: column; /* Stack vertically on mobile */
    align-items: center;
  }
}
