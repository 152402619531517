/* src/pages/AboutPage.css */
.about-page {
  font-family: Arial, sans-serif;
  line-height: 1.5;
  color: #333;
  padding: 20px;
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}

.about-header h1 {
  font-size: 2rem;
  color: #FF5722;
  margin-top: 3px;
  margin-bottom: 10px;
}

.about-header h2 {
  font-size: 1.3rem;
  color: #666;
  margin-bottom: 15px;
}

.about-content p {
  font-size: 1rem;
  margin: 15px 0;
}

.highlight {
  color: #FF5722;
  font-weight: bold;
}

.about-values h3 {
  font-size: 1.4rem;
  color: #444;
  margin: 15px 0;
}

.about-values ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
}

.about-values li {
  margin: 8px 0;
  font-size: 1rem;
}

.about-values li strong {
  color: #FF5722;
}

.about-footer {
  margin-top: 20px;
}

.cta-button {
  background-color: #FF5722;
  color: #fff;
  font-size: 1rem;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #ff7585;
}

/* General styling remains the same */

/* Card flip container */
.content-card {
perspective: 1000px; /* Creates a 3D perspective */
width: 100%;
height: 120px; /* Ensures fixed card height */
margin-bottom: 20px; /* Adds spacing between cards */
position: relative; /* Prevents content overflow */
overflow: hidden; /* Ensures nothing escapes the card */
border-radius: 15px; /* Adds rounded corners */
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
}

.card-inner {
width: 100%;
height: 100%;
position: relative;
transform-style: preserve-3d; /* Enable 3D transformation */
transition: transform 0.6s ease-in-out; /* Smooth flipping animation */
transform: rotateY(0); /* Default state */
}

/* Flip card on hover */
.content-card:hover .card-inner {
transform: rotateY(180deg); /* Flips the card */
}

/* Front and back sides */
.card-front,
.card-back {
position: absolute;
width: 100%; /* Matches container width */
height: 100%; /* Matches container height */
backface-visibility: hidden; /* Ensures only one side is visible */
border-radius: 15px; /* Matches the container's rounded corners */
left: 0; /* Aligns with parent */
top: 0; /* Aligns with parent */
}

/* Front side */
.card-front {
display: flex;
align-items: flex-end;
justify-content: flex-start;
background-color: #000;
color: #fff;
transform: rotateY(0); /* Ensure front is default */
}

.card-front img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 15px; /* Ensures image matches the rounded corners */
}

.card-title {
position: absolute;
top: 40%; /* Centers vertically */
/* left: 50%; Centers horizontally */
bottom: 16px;
left: 16px;
color: white;
font-size: 28px;
font-weight: bold;
text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

/* Back side */
.card-back {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #f8f9fa;
color: #333;
transform: rotateY(180deg); /* Back starts flipped */
padding: 10px; /* Add padding inside the card */
text-align: center; /* Centers the text horizontally */
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
border-radius: 15px; /* Ensures corners match front card */
width: 100%; /* Matches parent width */
height: 100%; /* Matches parent height */
overflow: hidden; /* Ensures content does not spill out */
box-sizing: border-box; /* Includes padding in size calculations */
}


.card-back p {
margin: 4px 0;
font-size: 14px;
/* font-weight: bold; */
}
