[data-component='Button'] {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto Mono', monospace;
  font-size: 12px; /* Base font size */
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  border: none;
  background-color: #ececf1;
  color: #101010;
  border-radius: 1000px;
  padding: 8px 24px;
  min-height: 42px;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  outline: none;

  &.button-style-action {
    background-color: #101010;
    color: #ececf1;
    &:hover:not([disabled]) {
      background-color: #404040;
    }
  }

  &.button-style-alert {
    background-color: #f00;
    color: #ececf1;
    &:hover:not([disabled]) {
      background-color: #f00;
    }
  }

  &.button-style-flush {
    background-color: rgba(255, 255, 255, 0);
  }

  &[disabled] {
    color: #999;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    background-color: #d8d8d8;
  }

  &:active:not([disabled]) {
    transform: translateY(1px);
  }

  .icon {
    display: flex;
    &.icon-start {
      margin-left: -8px;
    }
    &.icon-end {
      margin-right: -8px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-red .icon {
    color: #cc0000;
  }
  &.icon-green .icon {
    color: #009900;
  }
  &.icon-grey .icon {
    color: #909090;
  }
  &.icon-fill {
    svg {
      fill: currentColor;
    }
  }

  /* Add responsive styles */
  @media (max-width: 768px) {
    font-size: 14px; /* Slightly larger font size for better readability on mobile */
    padding: 12px 20px; /* Adjust padding to make buttons larger and more tappable */
    min-height: 48px; /* Increase height for better touch targets */
    gap: 10px; /* Increase gap slightly for mobile */

    .icon svg {
      width: 20px; /* Increase icon size slightly on mobile for better visibility */
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Even larger font for very small screens */
    padding: 14px 24px; /* Make buttons bigger on very small screens */
    min-height: 50px; /* Ensure buttons are large enough for touch interaction */
  }
}
