body {
  /* background-color: #f7997c; Keeps the background color */
  font-family: Arial, sans-serif; /* Font settings */
  color: #222; /* Text color */
  margin: 0; /* Removes any browser default margins */
  padding: 0; /* Resets padding */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box; /* Ensures padding is included in dimensions */
}

/* Stats section padding and alignment */
.stats {
  display: flex;
  justify-content: space-around;
  width: 100%; /* Ensures it spans the width of the screen */
  margin-bottom: 20px; /* Adds spacing below stats */
  padding: 10px; /* Padding inside the stats section */
  background-color: #fff; /* Optional: make it stand out */
  border-radius: 10px; /* Optional: rounded corners */
}

/* Heart rate display styles */
.heart-rate {
  font-size: 48px;
  margin: 20px 0; /* Adds spacing above and below */
}

h3 {
  font-size: 1.5em;
  color: #222;
  margin: 20px 0 10px;
  text-align: center;
}
/* Workout statistics like time, distance, pace styles */
.stats, .heartrate-unit{
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-size: 1.2em;
  color: #222;
  text-align: center;
}
.stats-bold {
  font-weight: bold;
  font-size: 2.2em;
  color: black;
  margin-bottom: 0.2em; /* Adjust the spacing between the bold text and label */
}
.stats p {
  margin: 0;
  line-height: 1.2; /* Adjust line-height to reduce spacing */
}

.heartrate {
  font-size: 6rem;
  font-weight: bold;
  color: #222;
  text-align: center;
  margin-bottom: 0.2em; /* Adjust the spacing between the bold text and label */
}

.heartrate-unit {
  font-size: 1.5rem;
  color: #222;
  text-align: center;
  margin: 0;
  line-height: 1.2; /* Adjust line-height to reduce spacing */
  display: inline-flex;
  align-items: center; /* Ensures alignment of the heart icon */
  justify-content: center;
}

.heart-icon {
  color: #ff0000; /* Red color */
  margin-left: 0;
  font-size: 1.5rem;
}

  .WorkoutPage h3 {
    text-align: center;
    color: #333333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .WorkoutPage p {
    color: #555555;
    font-size: 16px;
    margin: 8px 0;
  }
  
.button-overlay {
    position: absolute; 
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2; /* Higher z-index to appear above the map */
    width: 100%; /* CHANGED: Full width to span the screen */
    /* height: 75%; Cover three-quarters of the map */
    justify-content: center; /* Center the content within the overlay */
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
} 
/* Pause, Resume, and Stop buttons styling */
/* .pause-button, .resume-button, .stop-button { */
.pause-button {
  background-color: #b0b0b0; /* Darker grey background */
  color: #FF5722; /* Orange font color */
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none; /* No underline */
  margin-top: 8px;
  padding: 12px 16px; /* Ensures sufficient vertical padding */
  border-radius: 10px;
  text-align: center;
  width: 80%; /* Ensures all buttons have the same width */
  box-sizing: border-box; /* Ensures padding does not affect total width */
  display: flex; /* Centers content within the button */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
}

@media (min-width: 768px) {
  .pause-button, .resume-button, .stop-button {
      font-size: 1.5rem; /* Larger text for bigger screens */
      padding: 14px 20px; /* Slightly increased padding for better visibility */
  }
}

  .Button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 5px;
    font-size: 16px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  /* .Button:hover {
    background-color: #0056b3;
  } */
  
  .resume-stop-container {
    display: flex; /* Use flexbox for horizontal layout */
    justify-content: center; /* Center the buttons */
    align-items: center; /* Align vertically */
    gap: 10px; /* Add spacing between buttons */
    padding: 0 20px; /* Add space on the left and right */
    width: 100%; /* Ensure the container spans the full width */
    box-sizing: border-box; /* Include padding in the container width */

  }

.resume-button {
    flex-grow: 2; /* Make the Resume button take more space */
    background-color: #b0b0b0;
    color: #FF5722;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    justify-content: center; /* Horizontally center content */
}

.stop-button {
    flex-grow: 1; /* Make the Stop button take less space */
    background-color: #b0b0b0;
    color: #FF5722;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    justify-content: center; /* Horizontally center content */
}

  .Dialog {
    padding: 20px;
  }
  
  .DialogTitle {
    font-size: 20px;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .DialogContent {
    color: #555555;
    font-size: 16px;
  }
  
  .TextField {
    width: 100%;
    margin-top: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
  
  .TextField:focus {
    border-color: #007bff;
    outline: none;
  }
  

/* Heart Rate Zone Bar */
.heart-rate-zones {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.zone {
  width: 80px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-family: Arial, sans-serif;
}

/* Zone colors
.zone-1 { background-color: #89CFF0; }
.zone-2 { background-color: #32CD32; }
.zone-3 { background-color: #FFA500; }
.zone-4 { background-color: #FF007F; }
.zone-5 { background-color: #FF0000; } */

/* Zone colors */
.zone-1 { background-color: #87CEEB; }
.zone-2 { background-color: #98FF98; }
.zone-3 { background-color: #FFEB3B; }
.zone-4 { background-color: #FF7F50; }
.zone-5 { background-color: #E60000; }

/* Highlight active zone with triangle pointer */
.active {
  color: #000;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
  width: 200px; /* Adjust width as needed */
}

.triangle {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #d4ff3f;
}
